import * as React from "react";
import { Carousel } from "react-responsive-carousel";
import { fullImages } from "../../../helpers/imageHelpers";

export function ImageGallery() {
  return (
    <Carousel>
      {fullImages.map((image, i) => {
        return (
          <div key={i}>
            <img src={image}/>
          </div>
        );
      })}
    </Carousel>
  );
}
