import * as React from "react";
import { ImageGallery } from "./ImageGallery";

export function Galerie() {
  return (
    <>
      <div className="pt-5 pb-5 header">
        <h1>Unser Fotoalbum</h1>
        <h2>Fotos von vergangenen Veranstaltungen und Anlässen</h2>
      </div>
      <p>
        Bilder von Veranstaltungen und Anlässen anschauen. Auf ein Foto klicken
        um es zu vergrössern.
      </p>
      <ImageGallery/>
    </>
  );
}
