import React from "react"
import SEO from "../components/utils/SEO"
import Layout from "../components/layout/Layout"
import { Galerie } from "../components/pages/galerie/Galerie"

const GaleriePage = () => (
  <Layout>
    <SEO title="Galerie" />
    <Galerie/>
  </Layout>
)

export default GaleriePage
